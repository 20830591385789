.nav {
  width: 100vw;
  padding: 30px 30px 30px var(--padding-x);
  backdrop-filter: opacity(.8);
  overscroll-behavior: none;
  z-index: 100;
  height: 100%;
  height: fill-available;
  background-color: #000c;
  flex-direction: column;
  gap: 27px;
  height: -webkit-fill-available;
  display: flex;
  position: fixed;
  top: 0;
  right: -100vw;
  overflow-y: auto;
}

.nav-title {
  font-size: var(--nav-title-size);
  padding-bottom: 6px;
}

.nav-item {
  font-size: var(--nav-item-size);
}

.nav-list {
  flex-direction: column;
  gap: 6px;
  display: flex;
}

.nav-end {
  flex-direction: column;
  flex-grow: 1;
  justify-content: end;
  gap: 6px;
  display: flex;
}

.nav-end-top {
  max-width: 100px;
}

.btn-category {
  cursor: pointer;
}

.nav-btn-container {
  flex-direction: row-reverse;
  display: flex;
}

.nav-title-about {
  border-top: 3px solid #fff;
  padding-top: 13px;
}

.nav::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.webspace {
  text-align: center;
  padding-top: 20px;
}

.btn-open {
  width: var(--btn-open-width);
  height: var(--btn-open-height);
  background-image: url("hamburger_menu.e3d6afd1.svg");
  background-position: center;
  background-size: cover;
}

.btn-close {
  width: var(--btn-close-width);
  height: var(--btn-close-height);
  background-color: #0000;
  background-image: url("cross.08e2aabb.svg");
  background-position: center;
  background-size: cover;
}

@media (orientation: landscape) {
  .nav {
    max-width: 390px;
  }
}

@media (width >= 1024px) {
  .nav-title-about {
    margin-top: 10px;
  }
}

@media (width >= 1920px) {
  .nav-title-about {
    margin-top: 50px;
    padding-top: 18px;
  }
}
/*# sourceMappingURL=index.b476c687.css.map */
