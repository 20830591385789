.nav {
    position: fixed;
    right: -100vw;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 30px 30px 30px var(--padding-x);
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: opacity(0.8);
    gap: 27px;
    overflow-y: auto;
    overscroll-behavior: none;
    z-index: 100;
    height: 100%;
    height: fill-available;
    height: -webkit-fill-available;
}

.nav-title {
    font-size: var(--nav-title-size);
    padding-bottom: 6px;
}

.nav-item {
    font-size: var(--nav-item-size);
}

.nav-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.nav-end {
    display: flex;
    gap: 6px;
    flex-direction: column;
    justify-content: end;
    flex-grow: 1;
}

.nav-end-top {
    max-width: 100px;
}

.btn-category {
    cursor: pointer;
}

.nav-btn-container {
    display: flex;
    flex-direction: row-reverse;
}

.nav-title-about {
    border-top: 3px solid white;
    padding-top: 13px;
}

.nav::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.webspace {
    text-align: center;
    padding-top: 20px;
}


/* Nav Buttons */

.btn-open {
    width: var(--btn-open-width);
    height: var(--btn-open-height);
    background-size: cover;
    background-position: center;
    background-image: url('../assets/img/ui/hamburger_menu.svg');
}

.btn-close {
    width: var(--btn-close-width);
    height: var(--btn-close-height);
    background-size: cover;
    background-position: center;
    background-image: url('../assets/img/ui/cross.svg');
    background-color: transparent;
}

@media (orientation: landscape) {
    .nav {
        max-width: 390px;
    }
}

@media (min-width: 1024px) {
    .nav-title-about {
        margin-top: 10px;
    }
}

@media (min-width: 1920px) {
    .nav-title-about {
        margin-top: 50px;
        padding-top: 18px;
    }
}